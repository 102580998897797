<script>
import "chart.js/auto";
import { Bar } from "vue-chartjs";

export default {
  name: "BarChart",
  extends: Bar,
  comments: {
    Bar,
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
